import * as Sentry from '@sentry/browser';

export const initializeSentry = (
    sentryDsn: string | null,
    environment: string | null,
    appVersion: string | null,
    sampleRate: string,
    tracesSampleRate: string,
    frontendTag: 'webportal' | 'crm' | 'skadi-chat'
): void => {
    Sentry.init({
        dsn: sentryDsn ?? undefined,
        environment: environment ?? 'local',
        release: appVersion ?? 'UNKNOWN',
        sampleRate: sampleRate.length > 0 ? parseFloat(sampleRate) : 1.0,
        replaysOnErrorSampleRate: 0.01,
        replaysSessionSampleRate: 0,
        beforeSend(event, hint) {
            event.tags = event.tags || {};
            const traceId = hint?.originalException
                ? (hint.originalException as Obj).traceId
                : null;
            if (traceId) {
                event.tags['Trace-ID'] = traceId;
            }
            event.tags['Domain'] = frontendTag;
            return event;
        },
        integrations: [
            // Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
    });
};

type LogError = unknown;

export const logError = (error: LogError): void => {
    logToConsole(error);
    logToSentry(error);
};

const logToConsole = (error: LogError): void => {
    console.group('Error logged!');
    console.error(error);
    console.groupEnd();
};

const logToSentry = (error: LogError): void => {
    Sentry.captureException(error);
};
